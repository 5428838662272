import Calendly from "../components/Calendly";

function SchedulePage() {

    return (
        <>
            <Calendly />
        </>
    )
}

export default SchedulePage;