// import Calendly from "../components/Calendly";
import EstimateForm from "../components/EstimateForm";


function EstimatePage() {

    return (
        <>
        <EstimateForm/>

        {/* <Calendly /> */}
        </>
    )
}

export default EstimatePage;